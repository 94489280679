import { Avatar, Button, Card, Carousel, Col, Divider, Image, message, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

// COMPONENT
import CuponComponent from '../../components/Cupon';

// CONTROLLER
import PartLoading from '../Controle/PartLoading';

import tickt from '../../assets/imgs/tickt.png'
import { Link } from "react-router-dom";

class Screen extends React.Component {

    state = {
        loading_page: true,
        data: []
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        setTimeout(async () => {
            POST_API('/loja/cupons.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({data: res.data})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    render = () => {
        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Row gutter={[24,24]}>
                                {this.state.data.map((v, i) => (
                                    <Col xs={24} key={i}>
                                        <Card hoverable className="app-card">
                                            <Image src={tickt} preview={false} style={{marginBottom: 15, marginTop: 15}} />
                                            <Row gutter={[8,8]} justify={'space-between'} align={'middle'} style={{padding: '15px 25px', position: 'absolute', top: 0, height: '100%', width: '100%'}}>
                                                <Col span={24} style={{padding: 10, display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
                                                    <Typography className="app-card-ticket-text title">5%</Typography>
                                                    <Typography className="app-card-ticket-text" style={{marginTop: -10}}>DESCONTO</Typography>
                                                    <Divider style={{backgroundColor: '#FFF'}} />
                                                    <Typography style={{fontSize: 20, fontWeight: 700, textAlign: 'center'}}>{v.NAME}</Typography>
                                                    <Typography style={{fontSize: 16, textAlign: 'center', color: '#000', marginTop: 10, marginBottom: 30}}>VALIDADE: {(v.DATETIME_VALIDATE_FORMAT) ? v.DATETIME_VALIDATE_FORMAT : '-'}</Typography>
                                                    <Link to={v.ID}><Button type="primary" size="large" className="btn-primary">VER CUPOM</Button></Link>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;