import React from "react";
import { Button, Col, Form, Image, Layout, message, Row, Input, Carousel, Modal } from 'antd';
import { Link, Navigate, Outlet } from "react-router-dom";
import { getToken, POST_API, setToken, setConfig, delToken, delConfig } from "../../services/server-api";

// IMAGES
import logo from '../../assets/imgs/mini-logo-branca.png';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IoAlbumsOutline, IoCompassOutline, IoExitOutline, IoPersonOutline } from 'react-icons/io5';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        select: '',
    }

    componentDidMount = () => {

        const params = window.location.href;
        const values = params.split('/'); 
        this.setState({select: values[4] ? values[4] : ''})

        if (getToken()) {
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (!res.return) {
                    delToken()
                    this.setState({_navigate: true})
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'login' })
            }).finally(() => {
                this.setState({_loading: false})
            })
        } else {
            delToken()
            this.setState({_navigate: true})
        }
        this.setState({_loading: false})
    }

    onLogOut = () => {
        var self = this
        Modal.confirm({
            title:'Sair do sistema?',
            icon: <ExclamationCircleOutlined />,
            cancelText:'Não',
            okText:'Sim',
            className: 'sys-modal-confirm',
            onOk() { delToken(); delConfig(); self.setState({_navigate: true}) },
            onCancel() {},
        })
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to="/" />);
        }

        return (
            <Content>
                <Row style={{height: '100vh', overflowY: 'hidden', backgroundColor: '#ececec'}}>
                    <Col span={24} className="app-header">
                        <Image width={'60px'} src={logo} preview={false} />
                    </Col>
                    <Col span={24} className="app-body">
                        <Outlet />
                    </Col>
                    <Col span={24} className="app-footer">
                        <Link to="meuscupons"><Button shape="circle" onClick={() => this.setState({select: 'meuscupons'})} type="ghost" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0, height: 60, width: 60}}><IoAlbumsOutline color={this.state.select == "meuscupons" ? "#FD941C" : "#b8b8b8"} size={30} /></Button></Link>
                        <Link to=""><Button shape="circle" onClick={() => this.setState({select: ''})} type="ghost" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0, height: 60, width: 60}}><IoCompassOutline color={this.state.select == "" ? "#FD941C" : "#b8b8b8"} size={30} /></Button></Link>
                        <Link to="minhaconta"><Button shape="circle" onClick={() => this.setState({select: 'minhaconta'})} type="ghost" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0, height: 60, width: 60}}><IoPersonOutline color={this.state.select == "minhaconta" ? "#FD941C" : "#b8b8b8"} size={25} /></Button></Link>
                        <Button shape="circle" onClick={this.onLogOut} type="ghost" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0, height: 60, width: 60}}><IoExitOutline color={"#b8b8b8"} size={30} /></Button>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;