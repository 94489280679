import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

// ROTAS DE CONTROLE
import Page404 from '../pages/Controle/Page404'

// ROTAS DA LANDINGPAGE
import LandingPage from '../pages/Sistema/LandingPage/LandingPage';

// ROTAS DO APP
import LoginApp from '../pages/App/Login';
import CadastroApp from '../pages/App/Cadastro';
import DashboardApp from '../pages/App/Dashboard';
import ExplorarApp from '../pages/App/Explorar';
import ExplorarCuponApp from '../pages/App/Cupon';
import MeusCuponsApp from '../pages/App/MeusCupons';
import MinhaContaApp from '../pages/App/MinhaConta';
import MinhaContaMeusDadosApp from '../pages/App/MinhaConta.MeusDados';
import MinhaContaEnderecosApp from '../pages/App/MinhaConta.Enderecos';
import MinhaContaEnderecosFormApp from '../pages/App/MinhaConta.Enderecos.Form';
import MinhaContaFormasPagamentoApp from '../pages/App/MinhaConta.FormasPagamento';
import MinhaContaFormasPagamentoFormApp from '../pages/App/MinhaConta.FormasPagamento.Form';
import MinhaContaConfiguracoesApp from '../pages/App/MinhaConta.Configuracoes';

// ROTAS DO SISTEMA
import Login from '../pages/Autenticacao/Login';
import Register from '../pages/Autenticacao/Register';
import Dashboard from '../pages/Sistema/Dashboard/Dashboard';
import DashboardLoad from '../pages/Sistema/Dashboard/Load';
import Perfil from '../pages/Sistema/Perfil/Perfil';

// ROTAS CATEGORIAS
import CategoriasList from '../pages/Sistema/Categorias/List';
import CategoriasAdd from '../pages/Sistema/Categorias/Add';
import CategoriasEdit from '../pages/Sistema/Categorias/Edit';
import CategoriasTrash from '../pages/Sistema/Categorias/Trash';
// ROTAS EMPRESAS
import EmpresasList from '../pages/Sistema/Empresas/List';
import EmpresasAdd from '../pages/Sistema/Empresas/Add';
import EmpresasEdit from '../pages/Sistema/Empresas/Edit';
import EmpresasTrash from '../pages/Sistema/Empresas/Trash';
    // ROTAS EMPRESAS USUARIOS
    import EmpresasUsuariosList from '../pages/Sistema/Empresas/Usuarios/List';
    import EmpresasUsuariosAdd from '../pages/Sistema/Empresas/Usuarios/Add';
    import EmpresasUsuariosEdit from '../pages/Sistema/Empresas/Usuarios/Edit';
    import EmpresasUsuariosTrash from '../pages/Sistema/Empresas/Usuarios/Trash';
// ROTAS CLIENTES
import ClientesList from '../pages/Sistema/Clientes/List';
import ClientesAdd from '../pages/Sistema/Clientes/Add';
import ClientesEdit from '../pages/Sistema/Clientes/Edit';
import ClientesTrash from '../pages/Sistema/Clientes/Trash';
// ROTAS CUPONS
import CuponsList from '../pages/Sistema/Cupons/List';
import CuponsAdd from '../pages/Sistema/Cupons/Add';
import CuponsEdit from '../pages/Sistema/Cupons/Edit';
import CuponsGalery from '../pages/Sistema/Cupons/Galery';
import CuponsTrash from '../pages/Sistema/Cupons/Trash';
// ROTAS USUARIOS
import UsuariosList from '../pages/Sistema/Usuarios/List';
import UsuariosAdd from '../pages/Sistema/Usuarios/Add';
import UsuariosEdit from '../pages/Sistema/Usuarios/Edit';
import UsuariosTrash from '../pages/Sistema/Usuarios/Trash';
// ROTAS HISTORICO
import HistoricoList from '../pages/Sistema/Historico/List';
// ROTAS MINHA LOJA
import MinhaLojaList from '../pages/Sistema/MinhaLoja/MinhaLoja';

class AppStack extends React.Component {

    render = () => {

        return (
            <BrowserRouter>
                <Routes>
                    {/* LOJA */}
                    {/* <Route path="" element={<LandingPage />} /> */}
                    <Route path="" element={<Outlet />}>
                        <Route path="" element={<LoginApp />} />
                        <Route path="cadastro" element={<CadastroApp />} />
                        <Route path="dashboard" element={<DashboardApp />}>
                            <Route path="" element={<ExplorarApp />} />
                            <Route path="meuscupons" element={<MeusCuponsApp />} />
                            <Route path="minhaconta" element={<Outlet />} >
                                <Route path="" element={<MinhaContaApp />} />
                                <Route path="meusdados" element={<MinhaContaMeusDadosApp />} />
                                <Route path="enderecos" element={<Outlet />} >
                                    <Route path="" element={<MinhaContaEnderecosApp />} />
                                    <Route path="adicionar" element={<MinhaContaEnderecosFormApp type="add" />} />
                                    <Route path="atualizar/:id" element={<MinhaContaEnderecosFormApp type="edit" />} />
                                </Route>
                                <Route path="formaspagamento" element={<Outlet />}>
                                    <Route path="" element={<MinhaContaFormasPagamentoApp />} />
                                    <Route path="adicionar" element={<MinhaContaFormasPagamentoFormApp type="add" />} />
                                    <Route path="atualizar/:id" element={<MinhaContaFormasPagamentoFormApp type="edit" />} />
                                </Route>
                                <Route path="configuracoes" element={<MinhaContaConfiguracoesApp />} />
                            </Route>
                            <Route path=":id" element={<ExplorarCuponApp />} />
                        </Route>
                    </Route>
                    {/* SISTEMA */}
                    {/* <Route path="" element={<Login />}/>
                    <Route path="cadastrar" element={<Register />}/>
                    <Route path="dashboard" element={<Dashboard />}>
                        <Route path="" element={<DashboardLoad />}/>
                        <Route path="perfil" element={<Perfil />}/>
                        <Route path="parceiros" element={<Outlet />}>
                            <Route path="" element={<EmpresasList />} />
                            <Route path=":id/edit" element={<EmpresasEdit />} />
                            <Route path=":id/usuarios" element={<Outlet />}>
                                <Route path="" element={<EmpresasUsuariosList />} />
                                <Route path=":id/edit" element={<EmpresasUsuariosEdit />} />
                                <Route path="add" element={<EmpresasUsuariosAdd />} />
                                <Route path="trash" element={<EmpresasUsuariosTrash />} />
                            </Route>
                            <Route path="add" element={<EmpresasAdd />} />
                            <Route path="trash" element={<EmpresasTrash />} />
                        </Route>
                        <Route path="categorias" element={<Outlet />}>
                            <Route path="" element={<CategoriasList />} />
                            <Route path=":id/edit" element={<CategoriasEdit />} />
                            <Route path="add" element={<CategoriasAdd />} />
                            <Route path="trash" element={<CategoriasTrash />} />
                        </Route>
                        <Route path="clientes" element={<Outlet />}>
                            <Route path="" element={<ClientesList />} />
                            <Route path=":id/edit" element={<ClientesEdit />} />
                            <Route path="add" element={<ClientesAdd />} />
                            <Route path="trash" element={<ClientesTrash />} />
                        </Route>
                        <Route path="usuarios" element={<Outlet />}>
                            <Route path="" element={<UsuariosList />} />
                            <Route path=":id/edit" element={<UsuariosEdit />} />
                            <Route path="add" element={<UsuariosAdd />} />
                            <Route path="trash" element={<UsuariosTrash />} />
                        </Route>
                        <Route path="historico" element={<Outlet />}>
                            <Route path="" element={<HistoricoList />} />
                        </Route>
                        <Route path="minhaloja" element={<Outlet />}>
                            <Route path="" element={<MinhaLojaList />} />
                        </Route>
                        <Route path="cupons" element={<Outlet />}>
                            <Route path="" element={<CuponsList />} />
                            <Route path=":id/edit" element={<CuponsEdit />} />
                            <Route path=":id/galeria" element={<CuponsGalery />} />
                            <Route path="add" element={<CuponsAdd />} />
                            <Route path="trash" element={<CuponsTrash />} />
                        </Route>
                    </Route> */}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        )

    }

}

export default AppStack;