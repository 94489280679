import React from "react";
import { Button, Col, Form, Image, Layout, message, Row, Input, Carousel, Checkbox } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { getToken, POST_API, setToken, setConfig } from "../../services/server-api";

// IMAGES
import logo from '../../assets/imgs/logo-branca.png';
import plano from '../../assets/imgs/plano.jpg';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        termos: false
    }

    componentDidMount = () => {
        if (getToken()) {
            message.loading({ content: 'Carregando...', key: 'login' })
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({_navigate: true})
                } else {
                    message.warning({ content: res.msg, key: 'login' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'login' })
            }).finally(() => {
                this.setState({_loading: false})
            })
        }
        this.setState({_loading: false})
    }

    onSend = (values) => {

        message.loading({ content: 'Carregando...', key: 'login' })
        
        if (!this.state.termos) {
            message.warning({ content: 'Por favor, assinale a opção dos termos de uso', key: 'login' })
        } else {
            if (!values.EMAIL || !values.PASSWORD || !values.NAME) {
                message.warning({ content: 'Por favor, preencha todos os campos', key: 'login' })
            } else {
                this.setState({_loading: true})
                POST_API('/credential/registercliente.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'login' })
                        this.setState({_navigate: true})
                    } else {
                        message.warning({ content: res.msg, key: 'login' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'login' })
                }).finally(() => {
                    this.setState({_loading: false})
                })
            }
        }



    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to="/" />);
        }

        return (
            <Content style={{backgroundImage: `url(${plano})`}}>
                <Row className="container-login" align="middle" justify="center">
                    <Col className="container-login-fields" xs={20} md={8}>
                        <Image src={logo} preview={false} width={300} />
                        <Form layout="vertical" onFinish={this.onSend}>
                            <Form.Item name="NAME">
                                <Input className="app-input" placeholder="Digite seu nome" type="text"/>
                            </Form.Item>
                            <Form.Item name="EMAIL">
                                <Input className="app-input" placeholder="Digite seu e-mail" type="text"/>
                            </Form.Item>
                            <Form.Item name="PASSWORD">
                                <Input className="app-input" placeholder="Digite sua senha" type="password"/>
                            </Form.Item>
                            <Form.Item name="TERMOS">
                                <Checkbox value={this.state.termos} onChange={(v) => this.setState({termos: v.target.checked})} style={{color: '#fff'}}>Li e concordo com os termos uso</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button style={{height: 40}} block loading={this.state._loading} shape="round" className="btn-default" htmlType="submit" type="default">CADASTRA-SE</Button>
                                <Link to="/"><Button block className="btn-link btn-login" type="link">Fazer login</Button></Link>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;