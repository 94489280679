import { Avatar, Card, Carousel, Col, Image, message, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

// COMPONENT
import CuponComponent from '../../components/Cupon';

// CONTROLLER
import PartLoading from '../Controle/PartLoading';

import ticket from '../../assets/imgs/ticket.png'

class Screen extends React.Component {

    state = {
        loading_page: true,
        data: []
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        setTimeout(async () => {
            POST_API('/loja/cupons.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({data: res.data})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    render = () => {
        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Row gutter={[24,24]}>
                                {this.state.data.map((v, i) => (
                                    <Col xs={24} key={i}>
                                        <Card hoverable className="app-card">
                                            <Row gutter={[8,8]} justify={'space-between'} align={'middle'} style={{padding: '15px 25px'}}>
                                                <Avatar size={50} src={v.LOGO_ACCOUNT} />
                                                <Typography style={{fontSize: 20, fontWeight: 700}}>{v.NAME_ACCOUNT}</Typography>
                                                <Col span={24}>
                                                    <Typography className="app-card-ticket-text">{v.CODE}</Typography>
                                                    <Typography style={{fontSize: 18, fontWeight: 700, textAlign: 'center', color: '#646464', marginBottom: 10}}>VALIDADE: {(v.DATETIME_VALIDATE_FORMAT) ? v.DATETIME_VALIDATE_FORMAT : '-'}</Typography>
                                                    <Typography style={{fontSize: 20}}>{v.NAME}</Typography>
                                                    <Typography style={{color: 'var(--primary)', fontWeight: 600, fontSize: 20}}>5% desconto</Typography>
                                                    {v.CASHBACK > 0 ? <Typography style={{color: 'var(--primary)', fontWeight: 600, fontSize: 20}}>{v.CASHBACK}% de cashback</Typography> : null}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;