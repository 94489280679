import { Avatar, Button, Card, Carousel, Col, Divider, Image, message, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

// COMPONENT
import CuponComponent from '../../components/Cupon';

// CONTROLLER
import PartLoading from '../Controle/PartLoading';

import ticket from '../../assets/imgs/ticket.png'
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        loading_page: true,
        data: []
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        setTimeout(async () => {
            POST_API('/payment/search.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({data: res.data})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    render = () => {
        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        <Link to="/dashboard/minhaconta/"><Button type="link" shape="round" className="btn-link btn-conta" style={{justifyContent: 'flex-start'}}><IoArrowBack size={20} style={{marginRight: 8}} /> Voltar</Button></Link>
                    </Col>
                    <Col span={24}>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Row gutter={[8,8]}>
                                {this.state.data.length == 0 ? (
                                    <Col span={24}><Typography className="app-no-data">Nenhuma forma de pagamento cadastrada</Typography></Col>
                                ) : this.state.data.map((v,i) => (
                                    <Col span={24}>
                                        <Link to={`atualizar/${v.ID}`}>
                                            <Card>
                                                <Typography><b>{v.TYPE_TEXT}</b></Typography>
                                                <Typography>{v.SHOW}</Typography>
                                            </Card>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                    <Col span={24}>
                        <Link to="adicionar"><Button type="primary" className="btn-primary" block size="large">Adicionar nova forma de pagamento</Button></Link>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;