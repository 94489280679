import { Avatar, Button, Card, Carousel, Col, Image, message, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

// COMPONENT
import CuponComponent from '../../components/Cupon';

// CONTROLLER
import PartLoading from '../Controle/PartLoading';

import ticket from '../../assets/imgs/ticket.png'
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        id_cupon: window.location.href.split('/')[4],
        loading_page: true,
        data: null
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        
        setTimeout(async () => {
            POST_API('/loja/cupons_view.php', { token: getToken(), ID: this.state.id_cupon }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({data: res.data})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    render = () => {
        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        <Link to="/dashboard/"><Button type="link" shape="round" className="btn-link btn-conta" style={{justifyContent: 'flex-start'}}><IoArrowBack size={20} style={{marginRight: 8}} /> Voltar</Button></Link>
                    </Col>
                    <Col span={24}>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Row gutter={[24,24]}>
                                <Card hoverable className="app-card">
                                    <Row justify={'space-between'} align={'middle'}>
                                        <Avatar style={{marginLeft: 15, marginBottom: 10, marginTop: 10}} size={50} src={this.state.data.LOGO_ACCOUNT} />
                                        <Typography style={{marginRight: 15, fontSize: 20, fontWeight: 700}}>{this.state.data.NAME_ACCOUNT}</Typography>
                                        <Col span={24}>
                                            <Carousel>
                                                {this.state.data.FOTOS.map((v,i) => (
                                                    <Image width={'100%'} src={v.URL} preview={false} />
                                                ))}
                                            </Carousel>
                                        </Col>
                                        <Col span={24} style={{paddingLeft: 15, paddingRight: 15, }}>
                                            <Typography style={{marginTop: 15, fontSize: 18, fontWeight: 700, textAlign: 'center', color: '#646464', marginBottom: 10}}>VALIDADE: {(this.state.data.DATETIME_VALIDATE_FORMAT) ? this.state.data.DATETIME_VALIDATE_FORMAT : '-'}</Typography>
                                            <Typography style={{fontSize: 20}}>{this.state.data.NAME}</Typography>
                                            {this.state.data.CASHBACK > 0 ? <Typography style={{color: 'var(--primary)', fontWeight: 600, fontSize: 20}}>{this.state.data.CASHBACK}% de cashback</Typography> : null}
                                        </Col>
                                        <Col span={24} style={{paddingLeft: 15, paddingRight: 15, marginBottom: 20, marginTop: 10}}>
                                            <Button  type="primary" size="large" block className="btn-primary">Baixar cupom</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;