import { Avatar, Button, Card, Carousel, Col, Divider, Form, Image, Input, message, Modal, Row, Select, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

// COMPONENT
import CuponComponent from '../../components/Cupon';

import bancos from '../../assets/json/bancos.json';

// CONTROLLER
import PartLoading from '../Controle/PartLoading';

import { Link, Navigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import ReactInputMask from "react-input-mask";

import { ExclamationCircleOutlined } from '@ant-design/icons';

class Screen extends React.Component {

    state = {
        _params: window.location.href.split('/'),
        _navigate: false,
        loading: false,
        loading_page: true,
        defaultValues: null,
        text: '',
        type: ''
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {

        const { type } = this.props

        if (type == 'add') {
            this.setState({loading_page: false, text: 'Adicionar nova forma de pagamento'})
        } else {
            this.setState({loading_page: true, text: 'Atualizar forma de pagamento'})
            setTimeout(async () => {
                POST_API('/payment/view.php', { token: getToken(), ID: this.state._params[7] }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        this.setState({defaultValues: res.data, type: res.data.TYPE})
                    } else {
                        message.error({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => this.setState({loading_page: false}))
            }, 500);
        }
        
    }

    onSend = (values) => {
        this.setState({loading: true})
        POST_API('/payment/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_navigate: true})
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({loading: false}))
    } 

    onDelete = () => {
        var self = this
        Modal.confirm({
            title: 'Excluir Forma de Pagamento?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            className: 'sys-modal-confirm',
            onOk() {
                self.setState({loading: true})
                POST_API('/payment/del.php', { token: getToken(), ID: self.state._params[7] }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.setState({_navigate: true})
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({loading: false}))
            },
            onCancel() {},
        })
    }


    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/dashboard/minhaconta/formaspagamento" />
        }

        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        <Link to="/dashboard/minhaconta/formaspagamento"><Button type="link" shape="round" className="btn-link btn-conta" style={{justifyContent: 'flex-start'}}><IoArrowBack size={20} style={{marginRight: 8}} /> Voltar</Button></Link>
                    </Col>
                    <Col span={24}>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Form onFinish={this.onSend} initialValues={this.state.defaultValues} layout="vertical" style={{backgroundColor: '#FFF', padding: 15, borderRadius: 8}}>
                                <Form.Item>
                                    <Typography className="app-no-data">{this.state.text}</Typography>
                                </Form.Item>
                                <Form.Item name="ID" hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="TYPE" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                    <Select size="large" onChange={(v) => this.setState({type: v})} placeholder="Tipo de Pagamento" >
                                        <Select.Option value="DA">Débito Automático</Select.Option>
                                        <Select.Option value="CD">Caartão de Débito</Select.Option>
                                    </Select>
                                </Form.Item>
                                { this.state.type == 'DA' ? (
                                    <>
                                        <Form.Item name="BANK_DA" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Select size="large" placeholder="Banco" >
                                                { bancos.map((v, i) => {
                                                    if (v.code) {
                                                        return <Select.Option key={i} value={`${v.code} - ${v.name}`}>{v.code} - {v.name}</Select.Option>
                                                    }
                                                }) }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="AGEN_DA" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input placeholder="Agência" size="large" />
                                        </Form.Item>
                                        <Form.Item name="CC_DA" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input placeholder="Conta" size="large" />
                                        </Form.Item>
                                    </>
                                ) : this.state.type == 'CD' ? (
                                    <>
                                        <Form.Item name="NUMBER_CD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <ReactInputMask placeholder="Número do cartão" mask="9999 9999 9999 9999" className="ant-input ant-input-lg css-j0nf2s" />
                                        </Form.Item>
                                        <Form.Item name="NAME_CD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input size="large" placeholder="Nome escrito no cartão" />
                                        </Form.Item>
                                        <Form.Item name="VALIDADE_CD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <ReactInputMask placeholder="Validade" mask="99/9999" className="ant-input ant-input-lg css-j0nf2s" />
                                        </Form.Item>
                                        <Form.Item name="COD_CD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <ReactInputMask placeholder="Número do cartão" mask="999" className="ant-input ant-input-lg css-j0nf2s" />
                                        </Form.Item>
                                    </>
                                ) : null }
                                {/* 
                                
                                <Form.Item name="STATE" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                    <Select placeholder="Estado" >
                                        <Select.Option value="SP">São Paulo</Select.Option>
                                    </Select>
                                </Form.Item> */}
                                <Form.Item>
                                    <Button disabled={(this.state.type == '')} loading={this.state.loading} htmlType="submit" type="primary" className="btn-primary" block size="large">Salvar</Button>
                                </Form.Item>
                                {this.props.type == 'edit' ? (
                                    <Form.Item>
                                        <Button onClick={this.onDelete} loading={this.state.loading} type="default" className="btn-default" block size="large">Deletar</Button>
                                    </Form.Item>
                                ) : null }
                            </Form>
                        )}
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;