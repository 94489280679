import { Avatar, Button, Card, Carousel, Col, Divider, Image, List, message, Row, Switch, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

// COMPONENT
import CuponComponent from '../../components/Cupon';

// CONTROLLER
import PartLoading from '../Controle/PartLoading';

import ticket from '../../assets/imgs/ticket.png'
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        loading_page: true,
        data: []
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        setTimeout(async () => {
            POST_API('/loja/cupons.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({data: res.data})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    render = () => {
        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        <Link to="/dashboard/minhaconta"><Button type="link" shape="round" className="btn-link btn-conta" style={{justifyContent: 'flex-start'}}><IoArrowBack size={20} style={{marginRight: 8}} /> Voltar</Button></Link>
                    </Col>
                    <Col span={24}>
                        <List
                            header={<Typography style={{textAlign: 'center'}}>Configurações</Typography>}
                            bordered
                            style={{backgroundColor: '#FFF'}}
                        >
                            <List.Item>
                                <Row style={{width: '100%'}} justify={'space-between'}>
                                    <Col>Geolocalização</Col>
                                    <Col><Switch defaultChecked  /></Col>
                                </Row>
                            </List.Item>
                        </List>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;