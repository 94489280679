import { Avatar, Button, Card, Carousel, Col, Divider, Form, Image, Input, message, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

// COMPONENT
import CuponComponent from '../../components/Cupon';

// CONTROLLER
import PartLoading from '../Controle/PartLoading';

import ticket from '../../assets/imgs/ticket.png'
import { Link, Navigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import ReactInputMask from "react-input-mask";

class Screen extends React.Component {

    state = {
        loading_page: true,
        loading: false,
        data: [],
        _navigate: false,
        defaultValues: null
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        setTimeout(async () => {
            POST_API('/credential/load.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({defaultValues: res.data})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    onSend = (values) => {
        this.setState({loading: true})
        POST_API('/credential/perfil.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_navigate: true})
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({loading: false}))
    } 

    onSendPass = (values) => {
        if (values.NEW_PASSWORD == values.NEW_PASSWORD_CONFIRM) {
            this.setState({loading: true})
            POST_API('/credential/password.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({_navigate: true})
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading: false}))
        } else {
            message.warning({ content: 'Senhas não coincidem!', key: 'screen' })
        }
    } 

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/dashboard/minhaconta/" />
        }

        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        <Link to="/dashboard/minhaconta/"><Button type="link" shape="round" className="btn-link btn-conta" style={{justifyContent: 'flex-start'}}><IoArrowBack size={20} style={{marginRight: 8}} /> Voltar</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Row className="app-row-conta">
                            <Col span={24}>
                                <Typography className="app-title">Dados</Typography>
                            </Col>
                            <Col span={24}>
                                {this.state.loading_page ? <center><PartLoading /></center> : (
                                    <Form layout="vertical" onFinish={this.onSend} initialValues={this.state.defaultValues}>
                                        <Form.Item name="NAME" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input size="large" placeholder="Seu nome" />
                                        </Form.Item>
                                        <Form.Item name="EMAIL" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input size="large" placeholder="Seu e-mail" />
                                        </Form.Item>
                                        <Form.Item name="CPF">
                                            <ReactInputMask placeholder="CPF" mask="999.999.999-99" className="ant-input ant-input-lg css-j0nf2s" />
                                        </Form.Item>
                                        <Form.Item name="PHONE">
                                            <ReactInputMask placeholder="Celular" mask="(99) 99999-9999" className="ant-input ant-input-lg css-j0nf2s" />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button loading={this.state.loading} htmlType="submit" block size="large" type="primary" className="btn-primary">Salvar</Button>
                                        </Form.Item>
                                    </Form>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row className="app-row-conta">
                            <Col span={24}>
                                <Typography className="app-title">Mudar Senha</Typography>
                            </Col>
                            <Col span={24}>
                                <Form layout="vertical" onFinish={this.onSendPass}>
                                    <Form.Item name="OLD_PASSWORD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                        <Input size="large" placeholder="Senha atual" type="password" />
                                    </Form.Item>
                                    <Form.Item name="NEW_PASSWORD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                        <Input size="large" placeholder="Nova senha" type="password" />
                                    </Form.Item>
                                    <Form.Item name="NEW_PASSWORD_CONFIRM" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                        <Input size="large" placeholder="Confirmar nova senha" type="password" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button loading={this.state.loading} htmlType="submit" block size="large" type="primary" className="btn-primary">Mudar</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;