import React, { useEffect, useState } from "react";
import { Avatar, Button, Card, Carousel, Col, Collapse, Divider, Drawer, Form, Image, Input, Layout, Row, Typography, message } from "antd";
import FooterComponent from '../../../components/FooterComponent'

import plano from '../../../assets/imgs/plano.jpg';
import parceria from '../../../assets/imgs/parceria.png';
import minilogo from '../../../assets/imgs/mini-logo-branca.png';
import logo from '../../../assets/imgs/logosplash.png';

import tela_animada from '../../../assets/gifs/tela-animada-fundo.gif';
import step1 from '../../../assets/gifs/step-1.gif';
import step2 from '../../../assets/gifs/step-2.gif';
import step3 from '../../../assets/gifs/step-3.gif';

import { IoAlbums, IoCompass, IoHome, IoLogoWhatsapp, IoPerson, IoReorderThreeSharp } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { POST_API, getToken } from "../../../services/server-api";
import { FaFacebookF, FaInstagram, FaLink, FaTwitter, FaYoutube } from "react-icons/fa";

function Screen () {

    const [mn, setMN] = useState(false)
    const [cor, setCor] = useState(false)
    const [categorias, setCategorias] = useState([])
    const [parceiros, setParceiros] = useState([])

    useEffect(function() {
        function positionScroll() {
            if (window.scrollY > 10) {
                setCor(true)
            } else {
                setCor(false)
            }
        }
        window.addEventListener('scroll', positionScroll)
        onRenderData()
    }, [])

    function onRenderData () {
        POST_API('/loja/categorias.php', { }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setCategorias(res.data)
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        })
        POST_API('/loja/empresas.php', { }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setParceiros(res.data)
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        })
    }

    return (
        <Layout style={{width: '100vw !important', overflowX: 'hidden'}}>
            <Layout.Content style={{width: '100% !important'}}>
                <Row>
                    <Col md={24} xs={0}>
                        <Image src={plano} preview={false} className="land-image" />
                        <Row className={(cor) ? "row-land-image active" : "row-land-image"}>
                            <Image src={minilogo} preview={false} className="land-logo" />
                            <div className="land-menu">
                                <Button href="#inicio" type="link" className="btn-link" icon={<IoHome size={16} style={{marginRight: 4}} />}>Inicio</Button>
                                <Button href="#cupons" type="link" className="btn-link" icon={<IoCompass size={18} style={{marginRight: 3}} />}>Nossos Cupons</Button>
                                <Button href="#parceiros" type="link" className="btn-link" icon={<IoAlbums size={18} style={{marginRight: 3}} />}>Nossos Parceiros</Button>
                                <Button href="#sobre" type="link" className="btn-link" icon={<IoPerson size={16} style={{marginRight: 4}} />}>Sobre Nós</Button>
                                <Button href="#contato" type="link" className="btn-link" icon={<IoLogoWhatsapp size={16} style={{marginRight: 4}} />}>Entre em Contato</Button>
                            </div>
                        </Row>
                    </Col>
                    <Col md={0} xs={24}>
                        <Image src={plano} preview={false} className="land-image" />
                        <Row className={(cor) ? "row-land-image active" : "row-land-image"}>
                            <Image src={minilogo} preview={false} className="land-logo" />
                            <Button onClick={() => setMN(true)} type="ghost" shape="circle" className="land-menu-button"><IoReorderThreeSharp color="#FFF" size={40} /></Button>
                        </Row>
                    </Col>
                    <Col span={24} className="land-content">
                        <Row>
                            <Col style={{paddingTop: 80}} span={24} id="inicio">
                                <Row gutter={[8,8]} align={'middle'}>
                                    <Col md={12} xs={24}>
                                        <Image src={tela_animada} preview={false} />
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Typography className="land-text-inicio">Ganhe cashback e ative cupons de desconto nas suas lojas favoritas.</Typography>
                                        <Typography className="land-subtext-inicio">Com a Amika Cupon Fashion Shop, você poderá receber inúmeros cupons de desconto e cashbacks das suas lojas favoritas.</Typography>
                                        <Typography className="land-subtext-inicio" style={{textAlign: 'center', fontWeight: 'bold'}}>Baixe o APP!</Typography>
                                        <div className="loja-social" style={{justifyContent: 'center', marginTop: 10}}>
                                            <Image width={'200px'} preview={false} src='https://www.cuponeria.com.br/images/footer/app-android.jpg'/>
                                            <Image width={'200px'} preview={false} src='https://www.cuponeria.com.br/images/footer/app-ios.jpg'  style={{marginLeft: 10}}/>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Typography className="land-inicio-step-text">Veja como nosso APP funciona</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[16,16]}>
                                            <Col md={8} xs={24}>
                                                <Image className="land-inicio-steps" src={step1} preview={false} />
                                                <Typography className="land-inicio-steps-text">Faça parte da família Amika por apenas R$ 1,99 por mês e tenha acesso a inúmeros cupons.</Typography>
                                            </Col>
                                            <Col md={8} xs={24}>
                                                <Image className="land-inicio-steps" src={step2} preview={false} />
                                                <Typography className="land-inicio-steps-text">Tendo nossa assinatura, você terá acesso a todos os cupons disponíveis, podendo escolher os que mais te agradarem.</Typography>
                                            </Col>
                                            <Col md={8} xs={24}>
                                                <Image className="land-inicio-steps" src={step3} preview={false} />
                                                <Typography className="land-inicio-steps-text">Depois de resgatar seu cupom, basta ir na loja selecionada e usar seu desconto ou cashback.</Typography>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{paddingTop: 80}} span={24} id="cupons">
                                <Row>
                                    <Col span={24}>
                                        <Typography className="land-inicio-step-text">Oferecemos cupons em diversas categorias</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[8,8]} style={{marginTop: 10}}>
                                            {categorias.map((v, i) => (
                                                <Col xs={24} md={6} key={i}>
                                                    <Card hoverable cover={<img alt="example" src={v.IMAGE} />}>
                                                        <Card.Meta style={{textAlign: 'center'}} title={v.NAME} description={v.DESCRIPTION} />
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{paddingTop: 80}} span={24} id="parceiros">
                                <Row gutter={[8,8]}>
                                    <Col span={24}>
                                        <Typography className="land-inicio-step-text">Principais Parceiros</Typography>
                                    </Col>
                                    <Col span={24} style={{marginBottom: 20}}>
                                        <Row gutter={[8,8]} style={{marginTop: 10}}>
                                            {parceiros.map((v, i) => (
                                                <Col md={8} xs={24} key={i}>
                                                    <Card hoverable style={{borderRadius:15}}>
                                                        <Card.Meta
                                                            avatar={<Avatar className="loja-avatar" src={v.LOGO} />}
                                                            title={v.NAME}
                                                            description={<Row>
                                                                <Typography>{v.DESCRIPTION}</Typography>
                                                                <div className="loja-social">
                                                                    { v.FACEBOOK ? <a target='_blank' rel="noreferrer" href={v.FACEBOOK} className="loja-social-facebook"><FaFacebookF color="#FFF" /></a> : null }
                                                                    { v.INSTAGRAM ? <a target='_blank' rel="noreferrer" href={v.INSTAGRAM} className="loja-social-instagram"><FaInstagram size={15} color="#FFF" /></a> : null }
                                                                    { v.YOUTUBE ? <a target='_blank' rel="noreferrer" href={v.YOUTUBE} className="loja-social-youtube"><FaYoutube size={15} color="#FFF" /></a> : null }
                                                                    { v.TWITTER ? <a target='_blank' rel="noreferrer" href={v.TWITTER} className="loja-social-twitter"><FaTwitter color="#FFF" /></a> : null }
                                                                    { v.WEB ? <a target='_blank' rel="noreferrer" href={v.WEB} className="loja-social-web"><FaLink size={15} color="#FFF" /></a> : null }
                                                                </div>
                                                            </Row>}
                                                        />
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                    <Col span={24} id="nossoparceiro">
                                        <Row align={'middle'}>
                                            <Col md={12} xs={24}>
                                                <Image src={parceria} preview={false} />
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Typography className="land-text-inicio">Quer ser nosso parceiro e anunciar seus cupons?</Typography>
                                                <Typography className="land-subtext-inicio">Nós da Amika Cupon Fashion Shop, oferecemos um ambiente totalmente gratuito para que você divulgue e distribuia seus cupons para todo o país. Clique no botão abaixo e faça parte da nossa família!</Typography>
                                                <Button href="http://painel.amikacupon.com.br/cadastrar" target="_blank" type="primary" className="btn-primary" style={{fontSize: 18, height: 42, marginTop: 10, fontWeight: '700'}}>Seja um parceiro!</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{paddingTop: 80}} span={24} id="sobre">
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Row align={'middle'} className="land-row-invert">
                                            <Col xs={24} md={12}>
                                                <Typography className="land-text-inicio">Quem Somos?</Typography>
                                                <Typography className="land-subtext-inicio"><Typography className="land-subtext-inicio">Nós da Amika Cupon Fashion Shop, oferecemos um ambiente totalmente gratuito para que você divulgue e distribuia seus cupons para todo o país. Clique no botão abaixo e faça parte da nossa família!</Typography></Typography>
                                            </Col>
                                            <Col xs={24} md={12} className="land-row-invert-image">
                                                <Image src={logo} preview={false} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24} id="perguntas">
                                        <Typography className="land-inicio-step-text">Perguntas Frequentes</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Divider>CUPONS</Divider>
                                        <Collapse>
                                            <Collapse.Panel header="Como utilizo os cupons?" key={1}>
                                                <Typography.Paragraph>Ao acessar o Cupon você pode buscar por lojas ou categoria de produtos e encontrar todos os cupons disponíveis.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Há algum custo para utilizar os cupons?" key={2}>
                                                <Typography.Paragraph>Sim, apenas R$ 1,99.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Por que o cupom não funciona?" key={3}>
                                                <Typography.Paragraph>Todos os cupons possuem regrinhas para utilização. Alguns são válidos apenas para produtos ou categorias selecionadas, outros para a primeira compra de cada CPF.</Typography.Paragraph>
                                                <Typography.Paragraph>Por isso, sempre fique atento a essas regrinhas, pois o cupom só funcionará corretamente se todas as especificações forem obedecidas.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Por que alguns cupons não têm código?" key={4}>
                                                <Typography.Paragraph>Os cupons possuem código. Você pode ver aqui como utilizá-los.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Realizei uma compra e estou com problemas." key={5}>
                                                <Typography.Paragraph>Sentimos muito que esteja com problemas no seu pedido :{'('}</Typography.Paragraph>
                                                <Typography.Paragraph>Infelizmente o Cupon não tem controle sobre prazos de entrega / valor de frete / política de troca. Somos responsáveis apenas pela divulgação dos cupons e ofertas. Nesse caso, o ideal é que entre em contato direto com a loja na qual realizou a compra para maiores informações.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Posso copiar o código do cupom e comprar na loja física?" key={6}>
                                                <Typography.Paragraph>Nossos cupons são válidos apenas para compras online, pois acreditamos no sucesso das compras virtuais!</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Tenho um cupom e quero compartilhar com vocês, o que devo fazer?" key={7}>
                                                <Typography.Paragraph>Você tem uma loja e quer cadastrá-la na AMIKA Cupon? Se sim, preencha nosso formulário e em breve entraremos em contato com maiores informações!</Typography.Paragraph>
                                                <Typography.Paragraph>Se ficou sabendo de um cupom de alguma loja já cadastrada na AMIKA Cupon e deseja compartilhar, é fácil, basta clicar aqui.</Typography.Paragraph>
                                                <Typography.Paragraph>Não esqueça de informar todas as regrinhas necessárias para a utilização correta do cupom no campo “descrição do cupom”.</Typography.Paragraph>
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Col>
                                    <Col span={24}>
                                        <Divider>CADASTRO</Divider>
                                        <Collapse>
                                            <Collapse.Panel header="Como me cadastrar no Cupon?" key={1}>
                                                <Typography.Paragraph>Basta acessar este link para criar sua conta com e-mail, ou via conta do Google, Facebook ou Apple.</Typography.Paragraph>
                                                <Typography.Paragraph>Também é possível criar sua conta pelo nosso aplicativo disponível na PlayStore e na App store.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Quais os benefícios de me cadastrar na AMIKA Cupon?" key={2}>
                                                <Typography.Paragraph>Além de economizar nas suas compras utilizando os nossos cupons e ficar por dentro das melhores ofertas da internet, você ainda pode receber cashback em suas compras com o nosso Programa de Cashback (dinheiro de volta).</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Quero trocar minha senha/esqueci minha senha, o que devo fazer?" key={3}>
                                                <Typography.Paragraph>É só clicar neste link e preencher o seu endereço de e-mail cadastrado na AMIKA Cupon. Aguarde alguns minutos e confira sua caixa de entrada. Nós te enviaremos uma redefinição de senha por e-mail.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Como faço para receber emails com as melhores ofertas e cupons?" key={4}>
                                                <Typography.Paragraph>Basta cadastrar o seu e-mail neste link e nós te enviaremos os melhores cupons e ofertas para você não perder nenhuma novidade!</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Realizei uma compra e estou com problemas." key={5}>
                                                <Typography.Paragraph>Sentimos muito que esteja com problemas no seu pedido :{'('}</Typography.Paragraph>
                                                <Typography.Paragraph>Infelizmente o Cupon não tem controle sobre prazos de entrega / valor de frete / política de troca. Somos responsáveis apenas pela divulgação dos cupons e ofertas. Nesse caso, o ideal é que entre em contato direto com a loja na qual realizou a compra para maiores informações.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Como faço para encerrar minha conta?" key={6}>
                                                <Typography.Paragraph>É uma pena que deseja descadastrar o seu e-mail do nosso site :{'('}</Typography.Paragraph>
                                                <Typography.Paragraph>Antes de encerrar a sua conta leve em consideração que o Cupon é um serviço gratuito e a partir de R$20,00 de saldo acumulado você pode transferir o seu dinheiro para uma conta bancária de sua preferência.</Typography.Paragraph>
                                                <Typography.Paragraph>Por aqui cuidamos de seus dados com toda privacidade e segurança necessária.</Typography.Paragraph>
                                                <Typography.Paragraph>Caso você realmente queira prosseguir com o encerramento de conta, você deve acessar sua conta e clicar em "Seus dados".</Typography.Paragraph>
                                                <Typography.Paragraph>Feito isso, você vai em “Encerrar minha conta”.</Typography.Paragraph>
                                                <Typography.Paragraph>Após confirmar que você está certo de seu encerramento, ele será feito de forma automática. Importante lembrar que ao encerrar a sua conta, qualquer saldo acumulado e que não foi resgatado é cancelado automaticamente.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="O que é alerta de cupons e cashback e como me cadastrar?" key={7}>
                                                <Typography.Paragraph>O alerta de cupons é uma extensão do Chrome, Opera e Edge que te avisa sempre que existir cupons e cashback para as lojas em que estiver navegando.</Typography.Paragraph>
                                                <Typography.Paragraph>Nunca mais esqueça que você pode economizar usando os nossos cupons de desconto e ainda ganhar cashback em sua compra!</Typography.Paragraph>
                                                <Typography.Paragraph>Para baixar o alerta em seu navegador, é fácil, basta acessar este link.</Typography.Paragraph>
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Col>
                                    <Col span={24}>
                                        <Divider>PARCERIAS</Divider>
                                        <Collapse>
                                            <Collapse.Panel header="Como divulgar cupons da minha loja?" key={1}>
                                                <Typography.Paragraph>Queremos conhecer melhor a sua loja, pois acreditamos que uma parceria de sucesso é aquela em que os dois lados se conhecem bem! Para isso, pedimos que preencha nosso formulário. Em breve, nossa equipe entrará em contato para maiores detalhes!</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Como atualizo as informações da minha loja (texto do cupom/logo)?" key={2}>
                                                <Typography.Paragraph>Se deseja editar dados de um cupom publicado, é simples, acesse o link https://www.amikacupon.com.br/minha-conta/cupons, clique em “editar cupom” e atualize as informações. Atenção: toda alteração precisa passar pela aprovação de nossa equipe. Então, caso altere alguma informação do seu cupom, o status dele será “Aguardando Moderação”. Esse processo pode levar até 72h.</Typography.Paragraph>
                                            </Collapse.Panel>
                                            <Collapse.Panel header="Por que não aceitaram meu cupom?" key={3}>
                                                <Typography.Paragraph>Pode haver diversos motivos pelos quais o seu cupom não foi aprovado, como por exemplo: o desconto não foi concedido, o desconto é aplicado em um produto ou categoria muito específica, o site não possui carrinho de compras, entre outros.</Typography.Paragraph>
                                                <Typography.Paragraph>O motivo da recusa do cupom é informado por e-mail.</Typography.Paragraph>
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{paddingTop: 80}} span={24} id="contato">
                                <Divider />
                                <Row>
                                    <Col xs={24} md={12}>
                                        <Typography className="land-text-inicio">Entre em contato conosco</Typography>
                                        <Typography className="land-subtext-inicio"><Typography className="land-subtext-inicio">Email para contato</Typography></Typography>
                                        <Typography className="land-subtext-inicio"><Typography className="land-subtext-inicio">Telefone para contato</Typography></Typography>
                                        <Typography className="land-subtext-inicio"><Typography className="land-subtext-inicio" style={{textTransform: 'uppercase', fontSize: 12}}>Horário de funcionamento</Typography></Typography>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form layout="vertical">
                                            <Form.Item label="Seu nome" name="NOME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                            <Form.Item label="Seu e-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                            <Form.Item label="Digite sua mensagem" name="MENSAGEM" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input.TextArea type='text' rows={8} />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button htmlType="submit" type="primary" className="btn-primary" style={{fontSize: 14, height: 35, marginTop: 10, fontWeight: '700'}}>Enviar</Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                        <Drawer onClose={() => setMN(false)} open={mn}  width={200}>
                            <Row gutter={[8,8]}>
                                <Button block href="#inicio" type="default" className="btn-default" style={{display: 'flex', alignItems: 'center'}} icon={<IoHome size={16} style={{marginRight: 4}} />}>Inicio</Button>
                                <Button block href="#cupons" type="default" className="btn-default" style={{display: 'flex', alignItems: 'center'}} icon={<IoCompass size={18} style={{marginRight: 3}} />}>Nossos Cupons</Button>
                                <Button block href="#parceiros" type="default" className="btn-default" style={{display: 'flex', alignItems: 'center'}} icon={<IoAlbums size={18} style={{marginRight: 3}} />}>Nossos Parceiros</Button>
                                <Button block href="#sobre" type="default" className="btn-default" style={{display: 'flex', alignItems: 'center'}} icon={<IoPerson size={16} style={{marginRight: 4}} />}>Sobre Nós</Button>
                                <Button block href="#contato" type="default" className="btn-default" style={{display: 'flex', alignItems: 'center'}} icon={<IoLogoWhatsapp size={16} style={{marginRight: 4}} />}>Entre em Contato</Button>
                            </Row>    
                        </Drawer>
                    </Col>
                    <Col span={24}>
                        <FooterComponent />
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );

}

export default Screen